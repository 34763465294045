// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css



@font-face {
    font-family:"icon";
    src:url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.eot");
    font-weight:normal;
    font-style:normal;
}
@font-face {
	font-family:"icon";
	src:url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.eot");
	src:url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.eot?#iefix") format("embedded-opentype"),
		url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.woff") format("woff"),
		url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.ttf") format("truetype"),
		url("../../statics/icons/icon-f731c0b2795b3b0782b0d9f13d37300d.svg?#icon") format("svg");
	font-weight:normal;
	font-style:normal;
}


// Bootstrap Overrides
[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"icon";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

// Mixins

.icon-address, 
.icon-age, 
.icon-arrow_down, 
.icon-arrow_down_small, 
.icon-arrow_left, 
.icon-arrow_right, 
.icon-arrow_sprunganker, 
.icon-arrow_up, 
.icon-arrow_up_small, 
.icon-calendar, 
.icon-close, 
.icon-document, 
.icon-download, 
.icon-email, 
.icon-error, 
.icon-extern, 
.icon-facebook, 
.icon-google, 
.icon-hand, 
.icon-info, 
.icon-intern, 
.icon-language, 
.icon-lesezeit, 
.icon-link, 
.icon-links, 
.icon-list, 
.icon-lupe, 
.icon-mail, 
.icon-map-marker, 
.icon-mobile, 
.icon-navi, 
.icon-navi_arrow_left, 
.icon-navi_arrow_right_subnavi, 
.icon-navi_close_menue, 
.icon-navi_menue, 
.icon-navi_search, 
.icon-phone, 
.icon-pin, 
.icon-pin_plus, 
.icon-preview, 
.icon-print, 
.icon-rss, 
.icon-signet, 
.icon-spinner, 
.icon-star, 
.icon-subburger, 
.icon-th-list, 
.icon-th, 
.icon-thema, 
.icon-tick, 
.icon-twitter, 
.icon-type, 
.icon-vcard, 
.icon-view, 
.icon-youtube { 
	&:before {
		font-family:"icon";
		display:inline-block;
		font-weight:normal;
		font-style:normal;
		text-decoration:inherit;
	}
}


// Icons

.icon-address {
	&:before {
		content:"\f101";
	}
	
}


.icon-age {
	&:before {
		content:"\f102";
	}
	
}


.icon-arrow_down {
	&:before {
		content:"\f103";
	}
	
}


.icon-arrow_down_small {
	&:before {
		content:"\f104";
	}
	
}


.icon-arrow_left {
	&:before {
		content:"\f105";
	}
	
}


.icon-arrow_right {
	&:before {
		content:"\f106";
	}
	
}


.icon-arrow_sprunganker {
	&:before {
		content:"\f107";
	}
	
}


.icon-arrow_up {
	&:before {
		content:"\f108";
	}
	
}


.icon-arrow_up_small {
	&:before {
		content:"\f109";
	}
	
}


.icon-calendar {
	&:before {
		content:"\f10a";
	}
	
}


.icon-close {
	&:before {
		content:"\f10b";
	}
	
}


.icon-document {
	&:before {
		content:"\f10c";
	}
	
}


.icon-download {
	&:before {
		content:"\f10d";
	}
	
}


.icon-email {
	&:before {
		content:"\f10e";
	}
	
}


.icon-error {
	&:before {
		content:"\f10f";
	}
	
}


.icon-extern {
	&:before {
		content:"\f110";
	}
	
}


.icon-facebook {
	&:before {
		content:"\f111";
	}
	
}


.icon-google {
	&:before {
		content:"\f112";
	}
	
}


.icon-hand {
	&:before {
		content:"\f113";
	}
	
}


.icon-info {
	&:before {
		content:"\f114";
	}
	
}


.icon-intern {
	&:before {
		content:"\f115";
	}
	
}


.icon-language {
	&:before {
		content:"\f116";
	}
	
}


.icon-lesezeit {
	&:before {
		content:"\f117";
	}
	
}


.icon-link {
	&:before {
		content:"\f118";
	}
	
}


.icon-links {
	&:before {
		content:"\f119";
	}
	
}


.icon-list {
	&:before {
		content:"\f11a";
	}
	
}


.icon-lupe {
	&:before {
		content:"\f11b";
	}
	
}


.icon-mail {
	&:before {
		content:"\f11c";
	}
	
}


.icon-map-marker {
	&:before {
		content:"\f11d";
	}
	
}


.icon-mobile {
	&:before {
		content:"\f11e";
	}
	
}


.icon-navi {
	&:before {
		content:"\f11f";
	}
	
}


.icon-navi_arrow_left {
	&:before {
		content:"\f120";
	}
	
}


.icon-navi_arrow_right_subnavi {
	&:before {
		content:"\f121";
	}
	
}


.icon-navi_close_menue {
	&:before {
		content:"\f122";
	}
	
}


.icon-navi_menue {
	&:before {
		content:"\f123";
	}
	
}


.icon-navi_search {
	&:before {
		content:"\f124";
	}
	
}


.icon-phone {
	&:before {
		content:"\f125";
	}
	
}


.icon-pin {
	&:before {
		content:"\f126";
	}
	
}


.icon-pin_plus {
	&:before {
		content:"\f127";
	}
	
}


.icon-preview {
	&:before {
		content:"\f128";
	}
	
}


.icon-print {
	&:before {
		content:"\f129";
	}
	
}


.icon-rss {
	&:before {
		content:"\f12a";
	}
	
}


.icon-signet {
	&:before {
		content:"\f12b";
	}
	
}


.icon-spinner {
	&:before {
		content:"\f12c";
	}
	
}


.icon-star {
	&:before {
		content:"\f12d";
	}
	
}


.icon-subburger {
	&:before {
		content:"\f12e";
	}
	
}


.icon-th-list {
	&:before {
		content:"\f12f";
	}
	
}


.icon-th {
	&:before {
		content:"\f130";
	}
	
}


.icon-thema {
	&:before {
		content:"\f131";
	}
	
}


.icon-tick {
	&:before {
		content:"\f132";
	}
	
}


.icon-twitter {
	&:before {
		content:"\f133";
	}
	
}


.icon-type {
	&:before {
		content:"\f134";
	}
	
}


.icon-vcard {
	&:before {
		content:"\f135";
	}
	
}


.icon-view {
	&:before {
		content:"\f136";
	}
	
}


.icon-youtube {
	&:before {
		content:"\f137";
	}
	
}

