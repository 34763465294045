@import "../config/config.less";


@risk-color-nd: #A5A5A5;
@risk-color-low: #5DA904;
@risk-color-medium: #F7933A;
@risk-color-high: #DC0030;


.risk-indicators {
	box-sizing: border-box;

	*,
	*::before,
	*::after {
	    box-sizing: inherit;
	}
}


.risk-indicators__inner {
	background-color: #fff;
	padding: 20px 0;
	display: flex;
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__inner {
		flex-direction: column;
		align-items: center;
	}
}


.risk-indicators__col-list,
.risk-indicators__col-map {
	padding: 0 20px;
}


.risk-indicators__col-list {
	flex: 0 0 33.3333%;
	max-width: 33.3333%;
	min-width: 250px;
	display: flex;
	align-items: center;
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__col-list {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		margin-bottom: 20px;
	}
}


.risk-indicators__col-map {
	flex: 0 1 66.6666%;
	max-width: 66.6666%;
	position: relative;
	display: flex;
	align-items: center;
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__col-map {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		justify-content: center;
	}
}


.risk-indicators__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__list {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #cecece;
	}
}


.risk-indicators__item {
	border-bottom: 1px solid #cecece;

	&:first-of-type {
		border-top: 1px solid #cecece;
	}
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__item {
		flex: 0 0 50%;
		padding: 0 1px;

		&:first-of-type {
			border-top: 0;
		}

		.risk-indicators__link {
			height: 100%;
		}
	}
}


.risk-indicators__link {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 8px 8px 8px 1.5em;
	min-height: 56px;
	font: 700 16px/20px "Open Sans Condensed", Arial, sans-serif;

	&--is-current {
		background-color: #cecece;
	}

	&:hover,
	&:focus {
		background-color: @bg-color;
	}

	&:before {
		position: absolute;
		left: 8px;
		top: 50%;
		transform: translate(0,-50%);
		content: "\f115";
		font-family: "icon";
		display: inline-block;
		font-weight: normal;
		font-style: normal;
		text-decoration: inherit;
	}
}

@media screen and (max-width: (@mquery-s) ) {

	.risk-indicators__link {
		min-height: 46px;
		font: 700 13px/18px "Open Sans Condensed", Arial, sans-serif;
	}
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__link {
		padding: 2px 2px 2px 1em;

		&:before {
			left: 2px;
		}
	}
}


.risk-indicators__map-wrap {
	padding-right: 80px;
	width: 100%;
}

@media screen and (max-width: (@mquery-m) ) {

	.risk-indicators__map-wrap {
		max-width: 500px;
	}
}

@media screen and (max-width: (@mquery-s) ) {

	.risk-indicators__map-wrap {
		padding-right: 100px;
	}
}


.risk-indicators__svg {
	width: 100%;
}


.risk-indicators__legend-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 20px;
	bottom: 0;
}


.risk-indicators__legend-item {
	font: 700 14px/23px "Open Sans Condensed", Arial, sans-serif;
	padding-left: 1.7em;
	position: relative;

	&::before {
		content: '';
		height: 16px;
		width: 16px;
		display: block;
		border-radius: 50%;
		background-color: @risk-color-nd;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-0.55em);
	}
}

@media screen and (max-width: (@mquery-s) ) {

	.risk-indicators__legend-item  {
		font: 700 12px/20px "Open Sans Condensed", Arial, sans-serif;

		&::before {
			height: 14px;
			width: 14px;
		}
	}
}


.risk-indicators__legend-item--nd {
	margin-top: 0.75em;
}

.risk-indicators__legend-item--low {

	&::before {
		background-color: @risk-color-low;
	}
}

.risk-indicators__legend-item--low-medium {

	&::before {
		background: linear-gradient(225deg, @risk-color-medium 50%, @risk-color-low 50%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@risk-color-low', endColorstr='@risk-color-medium', GradientType=1 );
	}
}

.risk-indicators__legend-item--medium {

	&::before {
		background-color: @risk-color-medium;
	}
}

.risk-indicators__legend-item--medium-high {

	&::before {
		background: linear-gradient(225deg, @risk-color-high 50%, @risk-color-medium 50%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@risk-color-medium', endColorstr='@risk-color-high', GradientType=1 );
	}
}

.risk-indicators__legend-item--high {

	&::before {
		background-color: @risk-color-high;
	}
}


// Initial stage of map colors

#rog_mom_map_latin_america {

	.risk-color {
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	#no_data .risk-color {
		opacity: 1;
	}
}









